import { type FormEvent, type KeyboardEvent, useContext, useEffect, useRef, useState } from 'react';
import { defineMessages, IntlContext } from 'react-intl';
import { v4 as uuid } from '@lukeed/uuid';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Breakpoint, useBreakpoint, useClickOutside } from '@jsmdg/yoshi';
import { type FragmentContext } from '../../shared/types/fragmentContext';
import { type PlaceApiError } from '../../shared/types/googlePlaces';
import { type Filter } from '../../shared/types/search';
import {
    trackLocateMeError,
    trackLocationTypeInClicked,
    trackLocationTypeInError,
    trackResetLocationClicked,
} from '../helper/locationFilter.tracking';
import { SearchReducerActionType } from '../reducers/searchReducer';
import { type SearchReducerValue } from '../types/searchReducer';
import { getActiveFilters } from './getActiveFilters';

type MappedLocationSuggestion = {
    title: string;
    subTitle: string;
    placeId: string;
};

type UseLocationFilterProps = {
    readonly onSubmit: (type: SearchReducerActionType, value?: SearchReducerValue) => void;
    readonly filter?: Filter;
    readonly locationName?: string;
    readonly shouldReset?: false;
    readonly err?: GeolocationPositionError;
};

const DEFAULT_DISTANCE = 100;
const TRACKING_ERROR_LABELS = {
    TECHNICAL_PROBLEM: 'TechnicalProblem',
    INVALID_LOCATION: 'InvalidLocation',
};
const messages = defineMessages({
    placeholderLong: {
        defaultMessage: 'Wo (PLZ oder Ort)',
    },
    placeholderShort: {
        defaultMessage: 'Wo?',
    },
    allLocationsPlaceholder: {
        defaultMessage: 'Alle Standorte',
    },
    invalidLocation: {
        defaultMessage: 'Vertippt? Diesen Ort konnten wir leider nicht finden.',
    },
    technicalProblem: {
        defaultMessage:
            'Leider ist die Ortsfilterung aus technischen Gründen momentan nicht verfügbar.',
    },
    currentLocation: {
        defaultMessage: 'Dein Standort',
    },
    currentLocationErrorMessage: {
        defaultMessage: 'Dein Standort konnte leider nicht ermittelt werden.',
    },
});

export function useLocationFilter({
    onSubmit,
    filter = {},
    locationName = '',
    shouldReset,
    err,
}: UseLocationFilterProps) {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { formatMessage } = useContext(IntlContext);
    const { getPlacesDetail, getPlacesSuggestions } = useFragmentContext<FragmentContext>();

    const [errorMessage, setErrorMessage] = useState('');
    const [location, setLocation] = useState(locationName);
    const [locationSuggestions, setLocationSuggestions] = useState<
        MappedLocationSuggestion[] | null
    >(null);
    const [selected, setSelected] = useState(-1);
    const [selectedPlaceId, setSelectedPlaceId] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [sessionToken, setSessionToken] = useState('');
    const filterRef = useRef<HTMLFormElement>(null);
    const isDesktop = useBreakpoint(Breakpoint.SM);
    const geolocationError = useRef({
        previousError: '',
        previousLocation: '',
    });

    useClickOutside(filterRef, () => {
        setIsActive(false);
    });

    const onLocationChange = (event: FormEvent) => {
        const target = event.target as HTMLInputElement;
        if (!isActive && !!target.value) {
            setIsActive(true);
        }

        setLocation(target.value);
    };

    const onClearLocation = () => {
        // location is removed, so we should not track it in active filters
        const { ...activeFilters } = filter;

        const activeFiltersString = getActiveFilters(activeFilters);
        trackResetLocationClicked('ResetLocation', activeFiltersString);
        setLocationSuggestions([]);
        setErrorMessage('');
        setLocation('');
        setSelected(-1);
        onSubmit(SearchReducerActionType.LocationFilterReset);
    };

    const onSuggestionClick = (placeId: string, suggestedLocationName: string) => {
        setSelectedPlaceId(placeId);
        setLocationSuggestions([]);
        setLocation(suggestedLocationName);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        let index = 0;

        switch (event.keyCode) {
            // up arrow
            case 38:
                event.preventDefault();
                if (selected > 0) {
                    setSelected(selected - 1);
                } else if (selected === -1) {
                    if (locationSuggestions && locationSuggestions?.length > 0) {
                        setSelected(locationSuggestions.length - 1);
                    }
                } else {
                    setSelected(-1);
                }

                break;
            // down arrow
            case 40:
                if (locationSuggestions && selected < locationSuggestions?.length - 1) {
                    setSelected(selected + 1);
                }

                break;
            // enter
            case 13:
                event.preventDefault();
                if (selected > -1) {
                    index = selected;
                }

                if (locationSuggestions?.[index]) {
                    setSelectedPlaceId(locationSuggestions[index].placeId);
                    setLocation(
                        `${locationSuggestions[index].title}, ${locationSuggestions[index].subTitle}`,
                    );
                }

                break;
            // escape
            case 27:
                setLocationSuggestions([]);
                break;
            default:
                setIsActive(false);
                break;
        }
    };

    useEffect(() => {
        if (locationName === 'no_location_name') {
            setLocation(formatMessage(messages.currentLocation));
            return;
        }

        setLocation(locationName);
    }, [formatMessage, locationName]);

    useEffect(() => {
        if (err && err instanceof window.GeolocationPositionError) {
            setErrorMessage(formatMessage(messages.currentLocationErrorMessage));

            trackLocateMeError(
                'LocateMe blocked',
                'Error - LocateMe blocked',
                formatMessage(messages.currentLocationErrorMessage),
            );
        } else {
            setErrorMessage('');
        }
    }, [err, formatMessage]);

    useEffect(() => {
        if (shouldReset) {
            setLocation('');
            setErrorMessage('');
        }
    }, [shouldReset]);

    useEffect(() => {
        const geolocationErrorTrackingHandler = (errorLabel: string, errorLocation: string) => {
            const { previousError, previousLocation } = geolocationError.current;
            if (
                !previousError ||
                errorLabel !== previousError ||
                (errorLabel === TRACKING_ERROR_LABELS.INVALID_LOCATION &&
                    !errorLocation.includes(previousLocation, 0))
            ) {
                geolocationError.current.previousError = errorLabel;
                geolocationError.current.previousLocation = errorLocation;

                trackLocationTypeInError(
                    TRACKING_ERROR_LABELS.INVALID_LOCATION,
                    formatMessage(messages.invalidLocation),
                );
            }
        };

        async function getSuggestions() {
            let token = sessionToken;
            if (!token) {
                token = uuid();
                setSessionToken(token);
            }

            let rawLocationSuggestions;
            try {
                rawLocationSuggestions = await getPlacesSuggestions(location, token);
            } catch (error) {
                let message = formatMessage(messages.technicalProblem);
                let label = TRACKING_ERROR_LABELS.TECHNICAL_PROBLEM;
                if ((error as Error).message === 'ZERO_RESULTS') {
                    message = formatMessage(messages.invalidLocation);
                    label = TRACKING_ERROR_LABELS.INVALID_LOCATION;
                }

                geolocationErrorTrackingHandler(label, location);
                setLocationSuggestions([]);
                setErrorMessage(message);
                return;
            }

            const mappedLocationSuggestions: MappedLocationSuggestion[] =
                rawLocationSuggestions.predictions.map(locationSuggest => {
                    return {
                        title: locationSuggest.structured_formatting.main_text,
                        subTitle: locationSuggest.structured_formatting.secondary_text,
                        placeId: locationSuggest.place_id,
                    };
                });
            setLocationSuggestions(mappedLocationSuggestions);
        }

        if (location && location !== locationName && !selectedPlaceId) {
            setErrorMessage('');
            getSuggestions();
        }
    }, [
        formatMessage,
        getPlacesSuggestions,
        location,
        locationName,
        selectedPlaceId,
        sessionToken,
    ]);

    useEffect(() => {
        async function getDetails() {
            try {
                setLocationSuggestions([]);
                const result = await getPlacesDetail(selectedPlaceId, sessionToken);

                setSessionToken('');
                onSubmit(SearchReducerActionType.Location, {
                    lat: result.result.geometry.location.lat,
                    long: result.result.geometry.location.lng,
                    name: result.result.formatted_address,
                    distance: DEFAULT_DISTANCE,
                });
                setErrorMessage('');
                const activeFiltersString = getActiveFilters(filter, ['Location', 'distance']);

                trackLocationTypeInClicked(
                    `${location}_${DEFAULT_DISTANCE}km`,
                    location,
                    activeFiltersString,
                );

                filterRef.current?.querySelector('input')?.blur();
                setSelectedPlaceId('');
            } catch (error) {
                const errorName = (error as PlaceApiError).response?.data?.name;

                if (errorName === 'InvalidLocationError') {
                    trackLocationTypeInError(
                        TRACKING_ERROR_LABELS.INVALID_LOCATION,
                        formatMessage(messages.invalidLocation),
                    );

                    setErrorMessage(formatMessage(messages.invalidLocation));
                }
            }
        }

        if (selectedPlaceId) {
            getDetails();
            setLocationSuggestions([]);
            setSelected(-1);
            setIsActive(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPlaceId]);

    return {
        filterRef,
        errorMessage,
        isDesktop,
        location,
        locationSuggestions,
        isActive,
        formatMessage,
        messages,
        selected,
        onSuggestionClick,
        setSelected,
        setIsActive,
        onLocationChange,
        handleKeyDown,
        onClearLocation,
    };
}
