import { Breakpoint, type SliderConfig } from '@jsmdg/yoshi';
import { PageType } from '../../../shared/enums/pageType';

type ConfigByBreakpoint = {
    [configKey in Breakpoint]: SliderConfig;
};

type ConfigByPageType = {
    [key in PageType]: ConfigByBreakpoint;
};

const pageSliderConfigHome: ConfigByBreakpoint = {
    [Breakpoint.XL]: {
        spaceBetween: 32,
        slidesPerView: 4,
        slidesPerGroup: 4,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.LG]: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.MD]: {
        spaceBetween: 24,
        slidesPerView: 4,
        slidesPerGroup: 4,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.SM]: {
        spaceBetween: 24,
        slidesPerView: 3,
        slidesPerGroup: 3,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint.XS]: {
        spaceBetween: 16,
        slidesPerView: 3,
        slidesPerGroup: 3,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint['2XS']]: {
        spaceBetween: 16,
        slidesPerView: 2,
        slidesPerGroup: 2,
        showPagination: true,
        showNavigation: true,
    },
    [Breakpoint['3XS']]: {
        spaceBetween: 16,
        slidesPerView: 2,
        slidesPerGroup: 2,
        showPagination: true,
        showNavigation: true,
    },
};

const pageSliderConfig: ConfigByPageType = {
    [PageType.Home]: pageSliderConfigHome,
};

export { pageSliderConfig };
