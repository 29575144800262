/* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return, @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-dynamic-delete, complexity */
import { SortingField, SortingOrder } from '../../shared/enums/sorting';
import { type LocationFilter, type RangeFilter, type Search } from '../../shared/types/search';
import { type SearchReducerValue } from '../types/searchReducer';

const isRangeFilter = (value?: SearchReducerValue): value is RangeFilter =>
    !!value && typeof value === 'object' && ('min' in value || 'max' in value);

const isLocationFilter = (value?: SearchReducerValue): value is LocationFilter =>
    !!value &&
    typeof value === 'object' &&
    'lat' in value &&
    'long' in value &&
    'distance' in value;

export type SearchReducerState = Search & { type?: SearchReducerActionType };

export type SearchReducerAction = {
    type: SearchReducerActionType;
    value?: SearchReducerValue;
    name?: string;
    boundary?: { lat1: number; lng1: number; lat2: number; lng2: number };
};

export enum SearchReducerActionType {
    Price = 'price',
    PriceMin = 'priceMin',
    PriceMax = 'priceMax',
    Location = 'location',
    LocationFilterReset = 'locationFilterReset',
    GeoCoordinates = 'geoCoordinates',
    Reset = 'Reset',
}

export function searchReducer(
    state: SearchReducerState,
    action: SearchReducerAction,
): SearchReducerState {
    switch (action.type) {
        case SearchReducerActionType.Price: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    ...(isRangeFilter(action.value)
                        ? {
                              price: {
                                  min: action.value.min,
                                  max: action.value.max,
                              },
                          }
                        : {}),
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.PriceMin: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    price: {
                        ...state.filter?.price,
                        ...(typeof action.value === 'number' ? { min: action.value } : {}),
                    },
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.PriceMax: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    price: {
                        ...state.filter?.price,
                        ...(typeof action.value === 'number' ? { max: action.value } : {}),
                    },
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.Location: {
            if (!isLocationFilter(action.value)) {
                return state;
            }

            const { lat, long, name, distance } = action.value;
            return {
                ...state,
                filter: {
                    ...state.filter,
                    location: {
                        lat,
                        long,
                        name,
                        distance,
                    },
                    boundary: null,
                },
                pagination: {
                    ...state.pagination!,
                    currentPage: 1,
                },
                type: action.type,
            };
        }

        case SearchReducerActionType.GeoCoordinates: {
            return {
                ...state,
                type: action.type,
            };
        }

        case SearchReducerActionType.LocationFilterReset: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    location: null,
                    boundary: null,
                },
                sorting: {
                    field: SortingField.SalesRank,
                    order: SortingOrder.Desc,
                },
                type: action.type,
            };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}
