import { type MouseEvent } from 'react';
import classNames from 'classnames';

import {
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
    trackEecPromotionClick,
    trackEecPromotionImpression,
} from '@jsmdg/tracking';
import { PureLink } from '@jsmdg/yoshi';
import { PageType as Type } from '../../../shared/enums/pageType';
import { type BaseImage } from '../../../shared/types/pageContent';
import { type TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { trackingTypeByPage } from '../../enums/trackingPageTypes';
import { CreativeName, PromotionType } from '../../enums/trackingPromotions';
import { enhanceUrlWithSearchParams } from '../../helper/enhanceUrlWithSearchParams';
import { useImpressionTracking } from '../../hooks';
import { CardImage } from '../CardImage/CardImage';
import styles from './PageSlide.module.scss';

type PageSlideProp = {
    readonly linkUrl: string;
    readonly image: BaseImage;
    readonly title: string;
    readonly trackingName?: string;
    readonly position: string;
    readonly positionHorizontal: string;
    readonly positionVertical: string;
    readonly pageType: Type;
    readonly trackingPrefix: TrackingCreativePrefix;
    readonly trackingPath?: string[];
};

const PageSlide = ({
    image,
    linkUrl,
    pageType,
    position,
    positionHorizontal,
    positionVertical,
    title,
    trackingName,
    trackingPath,
    trackingPrefix,
}: PageSlideProp): JSX.Element | null => {
    const name = title || 'no name';
    const id = trackingName || 'no id';

    const promotionTrackingPayload = {
        id,
        name,
        creative: `${trackingPrefix}_${trackingTypeByPage[pageType]}`,
        position,
    };

    const promotionTrackingEventData = {
        creativeName: CreativeName[trackingPrefix] ?? CreativeName.PageTile,
        creativeSlot: position,
        promotionName: name,
        promotionId: id,
        locationId: linkUrl,
        positionHorizontal,
        positionVertical,
        promotionType: PromotionType.Static,
    };

    const isListingPage = pageType !== Type.Home;

    const setImpressionTrackedElement = useImpressionTracking(() =>
        trackEecPromotionImpression([promotionTrackingPayload], promotionTrackingEventData),
    );

    const fullTrackingPath = [...(trackingPath || []), trackingName].join(' > ');

    const onLinkClick = (event: MouseEvent): void => {
        event.preventDefault();

        trackEecPromotionClick(promotionTrackingPayload, promotionTrackingEventData);
        if (trackingPath) {
            trackAnalyticsEvent({
                eventData: {
                    eventName: GA4EventName.ClickNavigationItem,
                    feature_category: GA4FeatureCategory.PageSliderNavigation,
                    click_element: fullTrackingPath,
                    click_text: title,
                    position_horizontal: positionHorizontal,
                    position_vertical: positionVertical,
                    click_url: window.location.origin + linkUrl,
                },
            });
        }

        window.location.href = enhanceUrlWithSearchParams(linkUrl);
    };

    return (
        <div
            className={classNames(styles.pageSlide, 'd-flex position-relative h-100 w-100')}
            ref={setImpressionTrackedElement}
        >
            <PureLink
                className={classNames(styles.pageSlideLink, 'd-flex flex-column w-100')}
                href={linkUrl}
                onClick={onLinkClick}
                internal
            >
                <CardImage
                    image={{ ...image, alt: title }}
                    className={classNames(
                        styles.pageSlideImage,
                        'mb-0-5x mb-sm-0 position-relative h-100',
                        {
                            [styles.isListingPage]: isListingPage,
                        },
                    )}
                    lazyLoad
                    alignTop
                />
                <div
                    className={classNames(styles.pageSlideTitleWrapper, 'position-relative', {
                        [styles.isListingPage]: isListingPage,
                    })}
                >
                    <div
                        className={classNames(styles.pageSlideTitle, 'theme-text-subtitle-text', {
                            [styles.isListingPage]: isListingPage,
                        })}
                    >
                        {title}
                    </div>
                </div>
            </PureLink>
        </div>
    );
};

export { PageSlide };
