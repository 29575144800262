export enum ContentType {
    ImageAndText = 'image_and_text',
    Wysiwyg = 'wysiwyg',
    ImageSlider = 'image_slider',
    PageSlider = 'page_slider',
    PageTileWidget = 'page_tile_widget',
    Bestseller = 'bestseller',
    LastSeen = 'last_seen',
    Highlight = 'highlight',
}
