/* eslint-disable complexity*/
import { type Filter } from '../../shared/types/search';
import { type UrlParameter } from '../types/urlParameterType';

export const toUrlSearchParameters = (filter?: Filter): string => {
    const urlParameter: UrlParameter = {};

    if (filter?.price?.min) {
        urlParameter.priceMin = filter.price.min;
    }

    if (filter?.price?.max) {
        urlParameter.priceMax = filter.price.max;
    }

    if (filter?.location) {
        if (filter?.location.lat !== undefined) {
            urlParameter.lat = filter.location.lat;
        }

        if (filter?.location.long !== undefined) {
            urlParameter.long = filter.location.long;
        }

        // this is the fix for a bug where location name is passed as an array due to a doubled url HIVE-7269
        const locationName =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            Array.isArray(filter.location.name)
                ? (filter.location.name.at(-1) as string)
                : filter.location.name;

        if (locationName && locationName.trim().length > 0) {
            urlParameter.locationName = locationName;
        }

        if (filter?.location.distance) {
            urlParameter.distance = filter.location.distance;
        }
    }

    if (filter?.includeTestProducts) {
        urlParameter.includeTestProducts = filter.includeTestProducts;
    }

    return new URLSearchParams(urlParameter as Record<string, string>).toString();
};
