const priceFormat = currencyCode => ({
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
});

const formatTrackingPriceRange = ({ currencyCode, locale, max, min }) => {
    if (!min && !max) {
        return 'not selected';
    }

    const minPrice = new Intl.NumberFormat(locale, priceFormat(currencyCode)).format(min);
    const maxPrice = max
        ? new Intl.NumberFormat(locale, priceFormat(currencyCode)).format(max)
        : 'not selected';

    return `${minPrice} - ${maxPrice}`;
};

export { formatTrackingPriceRange };
