import { StrictMode, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { trackCategoryTree } from '@jsmdg/tracking';
import {
    Breakpoint,
    Notification,
    NotificationSnackbar,
    NotificationVariant,
    SnackbarContextProvider,
    SnackbarPosition,
    useBreakpoint,
} from '@jsmdg/yoshi';
import { PageType } from '../../../shared/enums/pageType';
import { type PageRoot } from '../../../shared/types/pageRoot';
import { WebsiteMarkup } from '../Markup/WebsiteMarkup';
import { messages } from './messages';
import { PageContent } from './PageContent';
import styles from './Page.module.scss';

const Page = (props: PageRoot): JSX.Element => {
    const intl = useIntl();
    const upToXSBreakpoint = useBreakpoint(Breakpoint.XS);

    const { document, inPreview = false } = props;

    useEffect(() => {
        trackCategoryTree(document?.trackingPath, document?.trackingName);
    }, [document?.trackingPath, document?.trackingName]);

    return (
        <StrictMode>
            <SnackbarContextProvider>
                <WebsiteMarkup />
                <NotificationSnackbar
                    className={upToXSBreakpoint ? 'container' : 'px-2x'}
                    position={SnackbarPosition.Bottom}
                />
                {inPreview && (
                    <Notification
                        variant={NotificationVariant.Warning}
                        message={intl.formatMessage(messages.previewPageMessage)}
                    />
                )}
                <div className={styles.page}>
                    <PageContent document={document} pageType={PageType.Home} />
                </div>
            </SnackbarContextProvider>
        </StrictMode>
    );
};

export { Page };
