/* eslint-disable complexity */
import { type ReactNode } from 'react';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Wysiwyg } from '@jsmdg/yoshi';
import { ContentType } from '../../../shared/enums/contentType';
import { type PageType } from '../../../shared/enums/pageType';
import { type FragmentContext } from '../../../shared/types/fragmentContext';
import { type PageContent as PageContentType } from '../../../shared/types/pageContent';
import { type PageDocument } from '../../../shared/types/pageDocument';
import { TrackingCreativePrefix } from '../../enums/trackingCreativePrefix';
import { useCreatives } from '../../hooks/useCreatives';
import { BestsellerSlider } from '../BestsellerSlider';
import { Categories } from '../Categories';
import { HeroStage } from '../HeroStage';
import { HighlightSlider } from '../HighlightSlider';
import { ImageAndText } from '../ImageAndText';
import { LastSeenProductSlider } from '../LastSeenProductSlider';
import { PageSliderWrapper } from '../PageSliderWrapper';
import { PageTiles } from '../PageTiles';
import { USPSlider } from '../USPSlider';
import styles from './Page.module.scss';

type PageContentProps = {
    readonly document?: PageDocument;
    readonly pageType: PageType;
};

const PageContent = ({ document, pageType }: PageContentProps): JSX.Element | null => {
    const { heroStageCreatives, navigationCreatives } = useCreatives();
    const { isMydays } = useFragmentContext<FragmentContext>();

    const hasNavigationCreative = navigationCreatives.length > 0;

    let verticalPosition = hasNavigationCreative ? 1 : 0;

    const findUspPosition = (): number => {
        if (!document?.contents) return -1;

        const reversedContents = document.contents.slice().reverse();

        const matchingIndex = reversedContents.findIndex(content =>
            [ContentType.Wysiwyg, ContentType.PageSlider].includes(content.type),
        );

        return matchingIndex > -1 ? document.contents.length - 1 - matchingIndex : -1;
    };

    const renderPageContentItems = (content: PageContentType, index: number): ReactNode => {
        switch (content.type) {
            case ContentType.PageSlider:
                verticalPosition += 1;
                return (
                    <PageSliderWrapper
                        slides={content.data || []}
                        headline={content.title}
                        verticalPosition={verticalPosition}
                        hasNavigationCreative={hasNavigationCreative}
                        pageType={pageType}
                        crossLinkPageTitle={content?.crossLinkPageTitle}
                        crossLinkPageRef={content?.crossLinkPageRef}
                        trackingPrefix={TrackingCreativePrefix.PageSlider}
                        trackingPath={document?.trackingPath}
                    />
                );

            case ContentType.Wysiwyg:
                return <Wysiwyg content={content.value} />;

            case ContentType.ImageAndText:
                return (
                    <ImageAndText
                        image={content.image}
                        imagePosition={content.imagePosition}
                        content={content.content}
                        link={content.link}
                        target={content.target || ''}
                        linkLabel={content.linkLabel}
                        lazyLoad
                    />
                );
            case ContentType.ImageSlider: {
                verticalPosition += 1;
                if (!isMydays) verticalPosition += 1;

                return (
                    <HeroStage
                        galleryImages={content.galleryImages}
                        categories={content.categories}
                        heroStageCreatives={heroStageCreatives}
                        hasNavigationCreative={hasNavigationCreative}
                        verticalPosition={verticalPosition}
                    />
                );
            }

            case ContentType.LastSeen:
                verticalPosition += 1;
                return (
                    <LastSeenProductSlider
                        lazyLoad
                        pageType={pageType}
                        verticalPosition={verticalPosition}
                    />
                );
            case ContentType.PageTileWidget:
                verticalPosition += 1;

                return (
                    <>
                        <PageTiles
                            identifier={`${content.type}-${index}`}
                            tiles={content.pageTiles}
                            pageType={pageType}
                            isNextToNavigation={index < 2}
                            verticalPosition={verticalPosition}
                            hasNavigationCreative={hasNavigationCreative}
                            trackingPrefix={TrackingCreativePrefix.PageTile}
                        />
                        {!isMydays && (
                            <Categories
                                pageType={pageType}
                                verticalPosition={verticalPosition}
                                hasNavigationCreative={hasNavigationCreative}
                                withBlockMargin
                            />
                        )}
                    </>
                );

            case ContentType.Bestseller:
                verticalPosition += 1;
                return (
                    <BestsellerSlider
                        initialSearchResult={content.initialSearchResult}
                        moreResultsLink={content.moreResultsLink || ''}
                        lazyLoad
                        pageType={pageType}
                        verticalPosition={verticalPosition}
                    />
                );

            case ContentType.Highlight:
                verticalPosition += 1;
                return (
                    <HighlightSlider
                        initialSearchResult={content.initialSearchResult}
                        moreResultsLink={content.moreResultsLink || ''}
                        lazyLoad
                        pageType={pageType}
                        verticalPosition={verticalPosition}
                    />
                );

            default:
                return <div>Unknown element</div>;
        }
    };

    if (!document?.contents) return null;

    const uspPosition = findUspPosition();

    return (
        <>
            {document?.contents.map((content, index) => {
                const key = `row-${index}`;
                const isImageSLiderOrWysiwyg =
                    content.type === ContentType.ImageSlider ||
                    content.type === ContentType.Wysiwyg;
                const showUSPBanner = uspPosition === index;

                return (
                    <div key={key} className="grid">
                        <div
                            className={classNames('g-col-12', styles.row, styles.homeRow, {
                                'mb-0': isImageSLiderOrWysiwyg,
                                'mt-0': content.type === ContentType.ImageSlider,
                            })}
                        >
                            {/* show USP for all tenants except JS_AT before last Wysiwyg */}
                            {showUSPBanner && content.type === ContentType.Wysiwyg && <USPSlider />}
                            {renderPageContentItems(content, index)}
                            {/* show USP for JS_AT only after last PageSlider */}
                            {showUSPBanner && content.type === ContentType.PageSlider && (
                                <USPSlider />
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export { PageContent };
