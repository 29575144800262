import { type Filter } from '../../shared/types/search';
import { PriceFilterDefault } from '../enums/priceFilterDefault';

const getActiveFilters = (filter: Filter | undefined, activeFilters: string[] = []): string => {
    const priceMin = filter?.price?.min ?? PriceFilterDefault.Min;
    const priceMax = filter?.price?.max ?? PriceFilterDefault.Max;

    if (
        (priceMin > 0 && priceMin !== PriceFilterDefault.Min) ||
        priceMax < PriceFilterDefault.Max
    ) {
        activeFilters.push('Price');
    }

    if (filter?.location?.name) {
        activeFilters.push('Location');

        if (filter?.location?.distance) {
            activeFilters.push('distance');
        }
    }

    return [...new Set(activeFilters)].join(', ');
};

export { getActiveFilters };
