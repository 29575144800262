export enum SortingField {
    SalesRank = 'salesRank',
    Price = 'price',
    Rating = 'rating',
}

export enum SortingOrder {
    Asc = 'asc',
    Desc = 'desc',
}
