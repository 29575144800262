const regularConfig = {
    size: 48,
    sizeMobile: 48,
    useOffset: false,
    showBanner: true,
    showFeedback: true,
    showDiscount: true,
    showSelectionText: true,
    showTitle: false,
    isFullWidth: false,
    renderMarkup: false,
};

export enum PageType {
    Home = 'HOME',
}

export const pageConfig: { [key in PageType]: typeof regularConfig } = {
    [PageType.Home]: {
        ...regularConfig,
        renderMarkup: true,
    },
};
